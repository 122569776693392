import { Swiper } from "swiper/types";
import Instafeed from './instafeed';

window.customElements.define('insta-feed', Instafeed);

class CustomAccordionPanel extends HTMLElement {
    header: HTMLElement
    content: HTMLElement
    constructor() {
        super();
        this.header = this.querySelector('[slot="header"]');
        this.content = this.querySelector('[slot="content"]');
    }

    connectedCallback() {
        this.header.addEventListener('click', () => this.toggle());
        this.header.addEventListener('keydown', (e) => {
            if (e.key === 'Enter' || e.key === ' ') {
                this.toggle();
            }
        });
    }

    toggle() {
        this.dispatchEvent(new CustomEvent('toggle', {
            bubbles: true,
            composed: true
        }));
    }

    setActive(active: boolean) {
        this.header.setAttribute('aria-expanded', String(active));
        this.content.classList.toggle('active', active);
        this.content.setAttribute('aria-hidden', String(!active));
    }
}
window.customElements.define('custom-accordion-panel', CustomAccordionPanel);

class CustomAccordion extends HTMLElement {
    panels: CustomAccordionPanel[]
    activeIndex: number
    
    constructor() {
        super();
        this.panels = Array.from(this.querySelectorAll('custom-accordion-panel'));
        this.activeIndex = Number(this.getAttribute('data-index'));
    }

    connectedCallback() {
        this.panels.forEach((panel, index) => {
            panel.addEventListener('toggle', () => {
                if (this.activeIndex === index) {
                    this.setActivePanel(-1);
                } else {
                    this.setActivePanel(index);
                }
            });
        });
        this.setActivePanel(this.activeIndex);
    }

    setActivePanel(index) {
        this.activeIndex = index;
        this.panels.forEach((panel, i) => {
            panel.setActive(i === index);
        });
    }
}
window.customElements.define('custom-accordion', CustomAccordion);

class ProductHeroCarousel extends HTMLElement {
  swiper: Swiper
  
  constructor() {
      super();
      this.swiper = this.querySelector('swiper-container').swiper;
  }
  disconnectedCallback(){
      this.swiper.destroy();
  }
}
customElements.define('product-hero-carousel', ProductHeroCarousel);

//  Adapted from https://aaadaaam.com/notes/revisiting-pjax/
class PageTransition extends HTMLElement {
  async getPage(url) {
    try {
      const response = await fetch(url);
      const responseText = await response.text();
      const parser = new DOMParser();
      const doc = parser.parseFromString(responseText, 'text/html');
      window.history.pushState(null, null, url);
      this.transitionPage(doc);
    } catch (e) {
      window.location.href = url;
    }
  }

  transitionPage(doc) {
    const { head } = document;
    const newElements = doc.querySelectorAll('head > *');
    const oldElements = document.querySelectorAll('head > *');
    const newContent = doc.querySelector('page-transition').innerHTML;
    const containerStyles = getComputedStyle(this);
    const delay = parseFloat(containerStyles.transitionDuration.slice(0, -1));
    // transition out
    this.classList.add('transitioning');
    setTimeout(() => {
      // replace page elements
      this.innerHTML = newContent;
      oldElements.forEach((element) => element.remove());
      newElements.forEach((element) => head.appendChild(element));
      // transition in
      this.transitionable();
      window.scrollTo(0, 0);
      this.classList.remove('transitioning');
    }, delay * 1000);
  }

  transitionable() {
    // only act on relative urls
    const links = document.querySelectorAll('a[href^="/"]');
    links.forEach((link) => {
      if (link.getAttribute('listener') !== 'true') {
        link.setAttribute('listener', 'true');
        link.addEventListener('click', (e) => {
          // @ts-ignore
          if (!e.metaKey) {
            e.preventDefault();
            this.getPage(link.getAttribute('href'));
          }
        });
      }
    });
  }

  connectedCallback() {
    this.transitionable();
    // reload the page when history controls are used
    window.addEventListener('popstate', () => {
      window.location.reload();
    });
  }
}

window.customElements.define('page-transition', PageTransition);

// class CustomMarquee extends HTMLElement {
//   container: HTMLElement
//   track: HTMLElement
//   numElements: number;
  
//   constructor() {
//     super();
//     this.container = this.querySelector('.marquee');
//     this.track = this.querySelector('.marquee-track');
//     this.numElements = this.container.children.length;
    
//     // Create a shadow root
//     const shadowRoot = this.attachShadow({ mode: 'open' });

//     // Create a style element
//     const style = document.createElement('style');
//     style.textContent = `
//       :host {
//         display: block;
//         position: relative;
//         --marquee-elements: ${this.numElements};
//         --marquee-width: 100vw;
//         --marquee-elements-displayed: 8;
//         --marquee-element-width: calc(var(--marquee-width) / var(--marquee-elements-displayed));
//         --marquee-animation-duration: calc(var(--marquee-elements) * 3s);
//       }

//       .marquee {
//         width: var(--marquee-width);
//         white-space: nowrap;
//         overflow: hidden;
//       }

//       .marquee-track {
//         display: flex;
//         list-style: none;
//         height: 100%;
//         animation: scrolling var(--marquee-animation-duration) linear infinite;
//       }

//       @keyframes marquee {
//           0% { transform: translateX(0); }
//           100% { transform: translateX(calc(-1 * var(--marquee-element-width) * var(--marquee-elements))); }
//       }
//     `;

//     // Append the style element to the shadow root
//     shadowRoot.appendChild(style);

//     const container = document.createElement('div');
//     container.classList.add('marquee');
//     container.innerHTML = `
//       <ul class="marquee-track">
//         <slot></slot>
//       </ul>
//     `;
//   }
// }

// window.customElements.define('custom-marquee', CustomMarquee);